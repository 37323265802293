import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faCalendar, faClock, faUser } from '@fortawesome/pro-solid-svg-icons'
import CustomLink from './CustomLink'

class ColumnAlternating extends React.Component {
  render() {
    const Heading = (this.props.headingLevel && Number.isInteger(this.props.headingLevel)) ? `h${this.props.headingLevel}` : 'h3'
    return (
      <MDBRow className={`mx-1 alt-area ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="alt-right grow">
          {this.props.link ? (
            <CustomLink link={this.props.link} aria-label={this.props.title} > <GatsbyImage image={this.props.image} alt={this.props.alt} className="alt-bg-image" /> </CustomLink>
          ) : (
            <GatsbyImage image={this.props.image} alt={this.props.alt} className="alt-bg-image" />
          )}
        </MDBCol>

        <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="alt-left bg-color">
          <div className="alt-content">
            <MDBContainer>
              <MDBRow>
                {this.props.colour ? (
                  <>
                    {this.props.subtitle != null && (
                      <MDBCol lg="12" className="px-0 order-last">
                        <Heading className={ this.props.subtitleClass != null ? this.props.subtitleClass : `font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-large ${this.props.subtitlecolour}` }>
                          {this.props.subtitle}
                        </Heading>
                      </MDBCol>
                    )}

                    <MDBCol lg="12" className="px-0 order-first">
                      <div className={ this.props.titleClass != null ? this.props.titleClass : 'font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue' } dangerouslySetInnerHTML={{ __html: this.props.title }} />
                    </MDBCol>             
                  </> 
                )
                :
                ( 
                  <>
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <MDBCol lg="12" className="px-0 order-last">
                        <Heading className={ `font-w-400 letter-spacing-1 text-xs-medium text-medium mt-2 ${this.props.displaytitle}` }>
                          {this.props.subtitle}
                        </Heading>
                      </MDBCol>
                    )}

                    <MDBCol lg="12" className="px-0 order-first">
                      <p className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium mt-2"> <CustomLink link={this.props.link} className="effect-noline-blog">{this.props.title}</CustomLink> </p>
                    </MDBCol>
                  </>
                )}
              </MDBRow>
            </MDBContainer>
            
            <div className="mt-3 font-w-400 text-medium blog-card" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: this.props.description }} />

            {(this.props.date || this.props.author || this.props.readtime) && (   
              <div className="font-alt font-w-400 text-gray-light text-medium-small letter-spacing-1 mt-4">
                <MDBRow>
                  {this.props.date && (
                    <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faCalendar} className="mr-2" />{this.props.date}</MDBCol>
                  )}

                  {this.props.author && (
                    <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faUser} className="mr-2" />{this.props.author}</MDBCol>
                  )}

                  {this.props.readtime && (
                    <MDBCol lg="auto" xs="12"> <FontAwesomeIcon icon={faClock} className="mr-2" />{this.props.readtime} read</MDBCol>
                  )}
                </MDBRow>
              </div>
            )}


            {(this.props.link && this.props.colour) &&
              <CustomLink link={this.props.link} className={`mt-4 ${this.props.colour} noeffect`} aria-label={this.props.title} > <FontAwesomeIcon icon={faChevronRight} /> </CustomLink>
            }
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}
export default ColumnAlternating